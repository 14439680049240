<template>
	<v-container id="settings" fluid tag="section">
		<v-row>
			<v-col v-if="currentUserRole == 'admin'" cols="6">
				<v-card shaped class="fill-height py-6" elevation="1">
					<v-card-title>
						System Short Codes
						<v-spacer></v-spacer>

						<systemShortCodesManagement
							@refresh="refresh()"
							:user="currentUser"
							:isText="false"
						/>
					</v-card-title>
				</v-card>
			</v-col>
			<v-col v-if="currentUserRole == 'admin'" cols="6">
				<v-card shaped class="fill-height py-6" elevation="1">
					<v-card-title>
						System Templates
						<v-spacer></v-spacer>

						<systemTemplatesManagement
							@refresh="refresh()"
							:user="currentUser"
							:isText="false"
						/>
					</v-card-title>
				</v-card>
			</v-col>
			<v-col v-if="currentUser.agent" cols="6">
				<v-card shaped class="fill-height py-6" elevation="1">
					<v-card-title>
						My Templates Management
						<v-spacer></v-spacer>

						<agentTemplateManagement
							@refresh="refresh()"
							:user="currentUser"
							:isText="false"
						/>
					</v-card-title>
				</v-card>
			</v-col>
			<v-col v-if="false && currentUser.agent" cols="6">
				<v-card shaped class="fill-height py-6" elevation="1">
					<v-card-title>
						Signature Management
						<v-spacer></v-spacer>

						<agentSignatureManagement
							@refresh="refresh()"
							:user="currentUser"
							:isText="false"
						/>
					</v-card-title>
				</v-card>
			</v-col>
			<v-col cols="6" v-if="currentUserRole == 'admin'">
				<v-card
					@click="sendMessageDialog = true"
					class="fill-height py-6"
					elevation="1"
				>
					<v-card-title>
						Send Custom Message
						<v-spacer></v-spacer>

						<v-btn :color="'primary'" large icon>
							<v-icon large>
								mdi-send-circle
							</v-icon>
						</v-btn>
					</v-card-title>
				</v-card>
			</v-col>
		</v-row>
		<sendMessageDialog
			v-if="sendMessageDialog"
			:dialogModel="sendMessageDialog"
			:selectedModel="null"
			@closeDialog="sendMessageDialog = false"
			@created="sendMessageDialog = false"
		/>
	</v-container>
</template>

<script>
	export default {
		components: {
			sendMessageDialog: () => import("@/components/dialogs/sendMessageDialog"),
			systemShortCodesManagement: () =>
				import("@/components/general/systemShortCodesManagement.vue"),
			systemTemplatesManagement: () =>
				import("@/components/general/systemTemplatesManagement.vue"),
			agentSignatureManagement: () =>
				import("@/components/general/agentSignatureManagement.vue"),
			agentTemplateManagement: () =>
				import("@/components/general/agentTemplateManagement.vue")
		},
		data: () => ({
			sendMessageDialog: false
		}),
		computed: {},
		methods: {
			refresh() {
				// document.location.reload();
			}
		}
	};
</script>
